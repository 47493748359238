import React from 'react';
import Footer from '../components/footer';

const SuccessPage = () => (
  <div>
    <div
      className="portfolio-wrapper"
      style={{
        minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h1>Thank you!</h1>
      <p>Let me get back to you ASAP</p>
    </div>
    <Footer />
  </div>
);

export default SuccessPage;
